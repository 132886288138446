import { useEffect, useState } from 'react';

export const useHash = () => {
  const [hash, setHash] = useState(
    window?.location?.hash?.replace('#', '') ?? ''
  );
  useEffect(() => {
    const onHashChange = () => {
      if (window?.location?.hash) {
        setHash(window.location.hash.replace('#', ''));
      }
    };
    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);
  return { hash, setHash };
};
