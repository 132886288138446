import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useHash } from '@/hooks/useHash';

export function useMenuRouteMatch<TItem extends { index: number; url: string }>(
  links: TItem[]
) {
  const { locale = 'en', asPath } = useRouter();
  const [path] = asPath.split('#');
  const { hash } = useHash();

  const fullLocationMatch = useMemo(
    () =>
      links?.find(
        (item: { url?: string; index: number }) =>
          item?.url === window.location.href
      ),
    // -> force check full url match when hash from location changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [links, hash]
  );

  const localePathAndHashMatch = useMemo(
    () =>
      fullLocationMatch
        ? undefined
        : links?.find(
            (item: { url?: string; index: number }) =>
              item?.url === getPath({ locale, path, hash })
          ),
    [links, fullLocationMatch, locale, path, hash]
  );

  const pathAndHashMatch = useMemo(
    () =>
      localePathAndHashMatch
        ? undefined
        : links?.find(
            (item: { url?: string; index: number }) =>
              item?.url === getPath({ locale: undefined, path, hash })
          ),
    [localePathAndHashMatch, links, path, hash]
  );

  const localeAndPathMatch = useMemo(
    () =>
      pathAndHashMatch
        ? undefined
        : links?.find(
            (item: { url?: string; index: number }) =>
              item?.url === getPath({ locale, path, hash: undefined })
          ),
    [pathAndHashMatch, links, locale, path]
  );

  const pathMatch = useMemo(
    () =>
      localeAndPathMatch
        ? undefined
        : links?.find(
            (item: { url?: string; index: number }) => item?.url === path
          ),
    [localeAndPathMatch, links, path]
  );

  const hashMatch = useMemo(
    () =>
      pathMatch
        ? undefined
        : links?.find(
            (item: { url?: string; index: number }) => item?.url === `#${hash}`
          ),
    [pathMatch, links, hash]
  );

  const routeMatch =
    fullLocationMatch ??
    localePathAndHashMatch ??
    localeAndPathMatch ??
    pathMatch ??
    hashMatch ??
    null;

  return {
    locale,
    path,
    hash,
    links,
    getPath,
    fullLocationMatch,
    localePathAndHashMatch,
    localeAndPathMatch,
    pathMatch,
    hashMatch,
    routeMatch
  };
}

type LocalePathHashParams = { locale?: string; path: string; hash?: string };
const getPath = ({ locale, path, hash }: LocalePathHashParams) => {
  if (locale && path && hash) {
    return `/${locale}${path}#${hash}`;
  }
  if (path && hash) {
    return `${path}#${hash}`;
  }
  if (locale && path) {
    return `/${locale}${path}`;
  }
  if (path) {
    return path;
  }
  if (hash) {
    return `#${hash}`;
  }

  return undefined;
};
